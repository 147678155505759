import { useTranslation } from '@dreamstack/i18n'
import { ArrowRight, Clear } from '@dreamstack/icons'
import { SimpleInput } from '@dreamstack/simple-components'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import type { FunctionComponent } from 'react'
import React from 'react'
import 'twin.macro'
import TailwindConfig from '../../../../tailwind.config'
import type {
  PropertyAutocompleteProperties} from '../hooks/usePropertyAutocompleteProperties';
import {
  usePropertyAutocompleteProperties,
} from '../hooks/usePropertyAutocompleteProperties'
import { usePropertySearchContext } from '../next/PropertySearchContext'
import type { PropertySearchFilterValues } from '../next/PropertySearchFilter'
import { useApplyPropertiesFilter } from './../hooks/useApplyPropertiesFilter'

const AutocompleteChipsStyled = withStyles({
  groupLabel: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#2b2d36',
  },
  tag: {
    backgroundColor: 'rgba(43, 45, 54, 0.1)',
    height: 40,
    'border-radius': 0,
    position: 'relative',
    zIndex: 0,
    '& .MuiChip-label': {
      color: '#2b2d36',
    },
    '& span': {
      fontSize: '16px',

      fontWeight: 600,
    },
  },
  endAdornment: {
    top: 'auto',
    right: '12px',
  },
  clearIndicator: {
    color: TailwindConfig.theme.extend.colors.accentroGray.full,
  },
})(Autocomplete)

export const PropertySearchAutocomplete: FunctionComponent<React.PropsWithChildren<{
  displayArrow?: boolean
}>> = ({ displayArrow }) => {
  const { applyFilter } = useApplyPropertiesFilter()

  const options = usePropertyAutocompleteProperties()
  const {
    propertySearchFilterValues,
    setPropertySearchFilterValues,
    propertySearchReservationUiFilter,
  } = usePropertySearchContext()

  const t = useTranslation()

  const onChangeAutocomplete = (values: PropertyAutocompleteProperties[]) => {
    const valuesGroupes = groupBy(values, (value) => value.type)
    const currentCities = map(valuesGroupes['city'], (entry) => entry.value)
    const currentLocations = map(
      valuesGroupes['location_name'],
      (entry) => entry.value
    )
    const currentRegions = map(valuesGroupes['region'], (entry) => entry.value)
    setPropertySearchFilterValues({
      cities: currentCities,
      regions: currentRegions,
      locations: currentLocations,
    })
  }

  const autocompleteValue = options.filter(
    (option) =>
      (option.type === 'city' &&
        propertySearchFilterValues.cities?.includes(option.value)) ||
      (option.type === 'location_name' &&
        propertySearchFilterValues.locations?.includes(option.value)) ||
      (option.type === 'region' &&
        propertySearchFilterValues.regions?.includes(option.value))
  )

  const forceSubmitOnAutocomplete = (value: string) => {
    if (options.some((option) => option.value === value)) {
      let addedNewValue = false
      const addIfNotExists = (arr: string[], elem: string) => {
        if (!arr.includes(elem)) {
          arr.push(elem)
          addedNewValue = true
        }
      }

      const newValues = Object.assign({}, propertySearchFilterValues)
      options.forEach((option) => {
        if (option.value === value) {
          if (option.type === 'city' && !addedNewValue) {
            addIfNotExists(newValues.cities, value)
          }
          if (option.type === 'region' && !addedNewValue) {
            addIfNotExists(newValues.regions, value)
          }
          if (option.type === 'location_name' && !addedNewValue) {
            addIfNotExists(newValues.locations, value)
          }
        }
      })
      setPropertySearchFilterValues(newValues)
    } else {
      // user requested location that is not available, showing error page
      const enterKeyEvent: Event = new KeyboardEvent('keydown', {
        code: 'Enter',
        key: 'Enter',
        charCode: 13,
        keyCode: 13,
        view: window,
        bubbles: true,
      })
      inputRef.current?.dispatchEvent(enterKeyEvent)
    }
  }

  const inputRef = React.useRef<HTMLInputElement | null>(null)

  return (
    <AutocompleteChipsStyled
      multiple
      freeSolo
      ref={inputRef}
      loading={!options.length}
      id="property-search-autocomplete"
      options={options}
      groupBy={(options: unknown) =>
        (options as PropertyAutocompleteProperties).label
      }
      getOptionLabel={(option: unknown) =>
        (option as PropertyAutocompleteProperties).value
      }
      tw="w-full "
      clearText={t('accentro:autocomplete.clearText')}
      renderInput={(params) => (
        <SimpleInput
          label={`${t('accentro:city')}, ${t('accentro:location')}, ${t(
            'accentro:region'
          )}`}
          variant="outlined"
          tw="bg-accentroGray-50"
          {...params}
          inputProps={{
            ...params.inputProps,
            // setting autoComplete to 'new-password' is the way to go to disable Google Autofill
            // see: https://stackoverflow.com/a/57279509
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (params.inputProps as HTMLInputElement).value
              .length ? (
              <span>
                <button
                  onClick={() => {
                    setPropertySearchFilterValues({
                      cities: [],
                      regions: [],
                      locations: [],
                    })
                  }}
                >
                  <Clear tw="rounded-full h-px-24 w-px-24 m-px-4 hover:bg-gray-200" />
                </button>
                {displayArrow && (
                  <button
                    style={{ marginLeft: 13 }}
                    onClick={() => {
                      forceSubmitOnAutocomplete(
                        (params.inputProps as HTMLInputElement).value
                      )
                    }}
                  >
                    <ArrowRight tw="h-px-32 w-px-32 mt-px-4 bg-accentroAqua-full text-accentroWhite-full" />
                  </button>
                )}
              </span>
            ) : null,
          }}
        />
      )}
      value={autocompleteValue}
      onChange={(event, inputs: unknown) => {
        const newOptions: PropertyAutocompleteProperties[] = []
        forEach(
          inputs as (PropertyAutocompleteProperties | string)[],
          (input) => {
            if (typeof input === 'string') {
              // If the input matches one of the options, the first match is used hence the Priority is Region -> Location -> City
              // Example: Berlin exists as a Region and as a City inside options, if input = 'Berlin' then the Region is used
              // TODO: Discuss if the priority should be changed
              const newOption = options.filter((option) =>
                option.value.toLowerCase().includes(input.toLowerCase())
              )
              if (newOption.length > 0) {
                newOptions.push(newOption[0])
              } else {
                const newOptionToAdd = {
                  value: input,
                  type: 'city',
                  label: 'Stadt',
                } as PropertyAutocompleteProperties
                newOptions.push(newOptionToAdd)
              }
            } else {
              newOptions.push(input)
            }
          }
        )
        onChangeAutocomplete(newOptions)
      }}
      renderOption={(_option: unknown, { inputValue }) => {
        const option = _option as PropertyAutocompleteProperties
        const matches = match(option.value, inputValue)
        const parts = parse(option.value, matches)
        const optionAsFilterProp = {
          ...propertySearchFilterValues,

          cities: option.type === 'city' ? [option.value] : [],
          regions: option.type === 'region' ? [option.value] : [],
          locations: option.type === 'location_name' ? [option.value] : [],
        } as PropertySearchFilterValues
        const filterResults = applyFilter(
          optionAsFilterProp,
          true,
          propertySearchReservationUiFilter
        )
        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                }}
              >
                {part.text}
              </span>
            ))}
            <span tw="text-accentroAqua-full">
              &nbsp;({filterResults.length})
            </span>
          </div>
        )
      }}
    />
  )
}
