import { useTranslation } from '@dreamstack/i18n'
import type { ReducedPropertyPreview } from '@dreamstack/web/lib/prisma/properties'
import filter from 'lodash/filter'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { useMemo } from 'react'
import { useGetAllValidProperties } from './useGetAllValidProperties'

export interface PropertyAutocompleteProperties {
  value: string
  type: 'city' | 'location_name' | 'region'
  label: string
}

const getPropsForAutocomplete = (
  array: ReducedPropertyPreview[],
  key: PropertyAutocompleteProperties['type'],
  label: string
): PropertyAutocompleteProperties[] => {
  return sortBy(
    uniqBy(
      map(
        filter(array, (p) => !!p[key]),
        (p) => ({ value: p[key]!, type: key, label })
      ),
      'value'
    ),
    (p) => p.value
  )
}

export const usePropertyAutocompleteProperties =
  (): PropertyAutocompleteProperties[] => {
    const t = useTranslation()

    const keyLabel = useMemo(() => {
      return {
        city: t('accentro:city'),
        location_name: t('accentro:location'),
        region: t('accentro:region'),
      }
    }, [t])

    const { allProperties } = useGetAllValidProperties()

    const result = useMemo(() => {
      const allCities = getPropsForAutocomplete(
        allProperties,
        'city',
        keyLabel['city']
      )
      const allLocations = getPropsForAutocomplete(
        allProperties,
        'location_name',
        keyLabel['location_name']
      )
      const allRegions = getPropsForAutocomplete(
        allProperties,
        'region',
        keyLabel['region']
      )
      const options = [...allCities, ...allLocations, ...allRegions]
      return options
    }, [allProperties, keyLabel])
    return result
  }
