import { useLocale } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import { useMemo } from 'react'
import { usePropertySearchContext } from '../next/PropertySearchContext'
import { useIsReservationUi } from '../next/useIsReservationUi'

export const useGetAllValidProperties = () => {
  const { reservationProjectIds } = usePropertySearchContext()
  const isReservationUi = useIsReservationUi()
  const locale = useLocale()

  const skip = isReservationUi ? !reservationProjectIds : false

  const { data: allProperties, isLoading: loading } =
    trpc.property.getAllProperties.useQuery(
      {
        projectIds: isReservationUi ? reservationProjectIds : undefined,
        locale,
      },
      { enabled: !skip, trpc: { ssr: true }, staleTime: Infinity }
    )

  const loadingOrSkipping = (loading && !skip) || skip
  // return { allProperties, loading: loadingOrSkipping }
  // TODO: remove this saniy check once data is good
  const allValidProperties = useMemo(() => {
    const validProperties = (allProperties ?? []).filter(
      (p) => !!p.number_of_rooms
    )
    return validProperties
  }, [allProperties])

  return { allProperties: allValidProperties, loading: loadingOrSkipping }
}
