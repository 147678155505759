import { NewBuildingProp, OldBuildingProp } from '@dreamstack/real-estate-logic'
import { PropertyStatus } from '@dreamstack/web-graphql'
import type { ReducedPropertyPreview } from '@dreamstack/web/lib/prisma/properties'
import every from 'lodash/every'
import _filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import some from 'lodash/some'
import { useCallback } from 'react'
import { usePropertyPriceVisibility } from '../index'
import type { ReservationUiFilterValues } from '../next/PropertySearchContext'
import type { PropertySearchFilterValues } from '../next/PropertySearchFilter'
import {
  MaxFilterValue,
  RsCategoryFilterValueMapping,
  SortByOptions,
} from '../next/PropertySearchFilter'
import { useIsReservationUi } from './../next/useIsReservationUi'
import { useGetAllValidProperties } from './useGetAllValidProperties'

const SHOW_ALL_PROPERTIES_WHEN_NO_REGION_SELECTED = true

export const useApplyPropertiesFilter = () => {
  const isReservationUi = useIsReservationUi()
  const { allProperties: validProperties, loading } = useGetAllValidProperties()
  const propertyPriceVisibility = usePropertyPriceVisibility()
  const applyFilter = useCallback(
    (
      filter: PropertySearchFilterValues,
      skipSort = false,
      propertySearchReservationUiFilter: ReservationUiFilterValues | null = null
    ) => {
      return applyPropertiesFilter({
        filter,
        skipSort,
        propertySearchReservationUiFilter,
        validProperties,
        isReservationUi,
        propertyPriceVisibility,
      })
    },
    [validProperties, isReservationUi, propertyPriceVisibility]
  )

  return { applyFilter, loading }
}

export const applyPropertiesFilter = ({
  filter,
  skipSort = false,
  propertySearchReservationUiFilter = null,
  validProperties,
  isReservationUi,
  propertyPriceVisibility,
}: {
  filter: PropertySearchFilterValues
  skipSort?: boolean
  propertySearchReservationUiFilter?: ReservationUiFilterValues | null
  validProperties: ReducedPropertyPreview[]
  isReservationUi: boolean
  propertyPriceVisibility: ({
    status,
    price,
  }: {
    status?: PropertyStatus | null | undefined
    price?: number | null | undefined
  }) => boolean
}) => {
  const {
    livingSpace: maxLivingSpace,
    price: maxPrice,
    roomRange: maxRoomRange,
  } = MaxFilterValue
  const {
    regions,
    cities,
    locations,
    priceRange,
    roomRange,
    livingspace,
    rented,
    typeOfImmo,
    textSearch,
    constructionAgeType,
    rsCategories,
    otherFilters,
  } = filter

  // Should Filter By Region?
  const regionIsSelected = cities.length + locations.length + regions.length > 0

  //SKIP IF NOT PLACE WAS SELECTED
  if (!SHOW_ALL_PROPERTIES_WHEN_NO_REGION_SELECTED && !regionIsSelected) {
    return []
  }

  let filtered = [...validProperties]

  // ONLY RESERVATION UI
  if (isReservationUi) {
    const { showFree, showMyReservations, showHidden } =
      propertySearchReservationUiFilter || {}

    const freeStati = [PropertyStatus.FREE]

    filtered = filtered.filter(({ status, reservation_start_date }) => {
      const isMyReservation = reservation_start_date
      if (isMyReservation) return showMyReservations
      const isFree = includes(freeStati, status)
      if (isFree) return showFree
      return showHidden
    })

    //FULLTEXT SEARCH
    const relevantKeys: (keyof Omit<ReducedPropertyPreview, 'images'>)[] = [
      'id',
      'name',
      'address',
      'title',
    ]

    if (!!textSearch) {
      const textLower = textSearch.toLowerCase()
      filtered = filtered.filter((p) => {
        return some(relevantKeys, (key) => {
          return includes(p[key]?.toString().toLowerCase(), textLower)
        })
      })
    }
  }

  //Filter RENTED
  filtered = filtered.filter((p) => {
    if (rented === 'both') {
      return true
    } else if (rented === 'rented') {
      return p.rented === true
    } else {
      return p.rented === false
    }
  })

  //Filter constructionAgeType
  filtered = filtered.filter((p) => {
    if (constructionAgeType === 'both') {
      return true
    } else if (constructionAgeType === 'newbuilding') {
      const constructionAgeTypeValue = find(
        p.custom_fields,
        (ef) => ef.key === 'alt_neubau'
      )?.value
      return NewBuildingProp.includes(constructionAgeTypeValue)
    } else if (constructionAgeType === 'oldbuilding') {
      const constructionAgeTypeValue = find(
        p.custom_fields,
        (ef) => ef.key === 'alt_neubau'
      )?.value
      return OldBuildingProp.includes(constructionAgeTypeValue)
    } else {
      return false
    }
  })

  //Filter rs_type like house, apartment or industry
  filtered = filtered.filter((p) => {
    const propTypeNormalized = p.rs_type?.toLowerCase()
    const filterTypeNormalized = typeOfImmo?.toLowerCase()
    return (
      filterTypeNormalized === 'all' ||
      propTypeNormalized === filterTypeNormalized
    )
  })

  //Filter rs_category like raised ground floor or ground floor etc
  filtered = filtered.filter((p) => {
    return (
      !rsCategories.length ||
      (p.rs_category &&
        includes(rsCategories, RsCategoryFilterValueMapping[p.rs_category]))
    )
  })

  //Filter for other filter options like waterclose, lift, balcony etc
  filtered = filtered.filter((p) => {
    return every(otherFilters, (f) => {
      switch (f) {
        case 'waterClose':
          return find(p.custom_fields, (ef) => {
            return ef.key === 'wassernaehe'
          })?.value
        case 'lift':
          return find(p.equipment_features, (ef) => {
            return ef.key === 'lift'
          })?.value
        case 'balconyTerrace':
          return some(
            _filter(p.equipment_features, (ef) => {
              return ef.key === 'balcony' || ef.key === 'terrace'
            }),
            (v) => !!v.value
          )

        default:
          console.error(`Unknown filter option: ${f}`)
          return true
      }
    })
  })

  //Filter PRICE
  const priceFilterUntouched = priceRange[0] === 0 && priceRange[1] === maxPrice
  // Do not filter price if filter untouched
  if (!priceFilterUntouched) {
    filtered = filtered.filter((p) => {
      // Actually filter by price:
      return (
        typeof p.price === 'number' &&
        p.price >= priceRange[0] &&
        (priceRange[1] >= maxPrice || p.price <= priceRange[1])
      )
    })
  }

  //Filter LIVINGSPACE aka PROPERTY_SPACE_VALUE
  const livingFilterUntouched =
    livingspace[0] === 0 && livingspace[1] === maxLivingSpace
  if (!livingFilterUntouched) {
    filtered = filtered.filter(
      (p) =>
        typeof p.property_space_value === 'number' &&
        p.property_space_value >= livingspace[0] &&
        (livingspace[1] >= maxLivingSpace ||
          p.property_space_value <= livingspace[1])
    )
  }

  //Filter ROOMRANGE aka NUMBER_OF_ROOMS
  const roomFilterUntouched =
    roomRange[0] === 0 && roomRange[1] === maxRoomRange
  if (!roomFilterUntouched) {
    filtered = filtered.filter(
      (p) =>
        typeof p.number_of_rooms === 'number' &&
        p.number_of_rooms >= roomRange[0] &&
        (roomRange[1] >= maxRoomRange || p.number_of_rooms <= roomRange[1])
    )
  }

  //Filter LOCATIONS CITIES REGIONS (if selected)
  if (regionIsSelected) {
    filtered = filtered.filter(
      (p) =>
        (p.location_name && locations.includes(p.location_name)) ||
        (p.city && cities.includes(p.city)) ||
        (p.region && regions.includes(p.region))
    )
  }

  if (skipSort) return filtered

  const { sortBy, value } = filter

  if (
    [SortByOptions.PRICE_ASC, SortByOptions.PRICE_DESC].includes(sortBy) &&
    !isReservationUi
  ) {
    filtered.map((p) => {
      if (
        !propertyPriceVisibility({
          status: p.status ?? undefined,
          price: p.price ?? undefined,
        })
      ) {
        sortBy === 'price_asc' ? (p.price = maxPrice) : (p.price = 0)
      }
    })
    filtered = [
      ...orderBy(
        filtered,
        (prop) => prop[value],
        sortBy === 'price_asc' ? 'asc' : 'desc'
      ),
    ]
  }

  if (
    [
      SortByOptions.FIRST_PUBLISHED_AT_DESC,
      SortByOptions.FIRST_PUBLISHED_AT_ASC,
    ].includes(sortBy)
  ) {
    const nulls = filtered.filter((x) => x[value] === null)
    const nonNulls = filtered.filter((x) => x[value] !== null)
    filtered = [
      ...orderBy(
        nonNulls,
        (prop) => prop[value],
        sortBy === SortByOptions.FIRST_PUBLISHED_AT_ASC ? 'asc' : 'desc'
      ),
      ...nulls,
    ]
  }

  return filtered
}
