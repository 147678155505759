import type { FormControlLabelProps, RadioGroupProps } from '@material-ui/core'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import tw, { styled } from 'twin.macro'
import { SimpleFieldError } from './SimpleFieldError'

interface SimpleRadioProps {
  label?: string
  options: Omit<FormControlLabelProps, 'control'>[]
  radioGroupProps: RadioGroupProps
  isOnDarkBg?: boolean
  name?: string
}

const StyledLabelWrapper = styled.div<{}>(({}) => [
  tw`padding-top[9px] ml:padding-top[5px] mr-px-16 w-full text-sm md:text-base lg:text-base`,
])

// eslint-disable-next-line react/display-name
export const SimpleRadio = React.forwardRef<HTMLInputElement, SimpleRadioProps>(
  ({ name, label, radioGroupProps, options, isOnDarkBg = false }, ref) => {
    const formContext = useFormContext()
    const error = name
      ? formContext.getFieldState(name, formContext.formState).error?.message
      : undefined

    return (
      <FormControl
        component="fieldset"
        color="primary"
        style={isOnDarkBg ? { color: 'white' } : {}}
      >
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <RadioGroup {...radioGroupProps}>
          {options.map((option) => (
            <FormControlLabel
              inputRef={ref}
              key={option.value as any}
              control={
                <Radio
                  color="secondary"
                  style={isOnDarkBg ? { color: 'white' } : {}}
                />
              }
              {...option}
              label={
                option.label ? (
                  <StyledLabelWrapper tw="pt-0 mr-px-16 w-full text-sm md:text-base lg:text-base">
                    <p tw="ml-px-12 text-px-16 leading-px-24 ml:(text-px-20 leading-px-30)">
                      {option.label}
                    </p>
                  </StyledLabelWrapper>
                ) : null
              }
            />
          ))}
        </RadioGroup>
        {!!error && <SimpleFieldError>{error}</SimpleFieldError>}
      </FormControl>
    )
  }
)
