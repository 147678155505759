// fetch via propstack "custom_fields" -> "alt_neubau"

export const PropStackKeyOfOldNewBuildingProp = 'alt_neubau'

enum OldNewBuildingProp {
  OLD_DE = 31, //Altbau
  NEW_DE = 32, //Neubau
  OLD_EN = 876, //Old building
  NEW_EN = 888, //New building
  OLD_IT = 814, //Vecchio edificio
}

export const NewBuildingProp = [
  OldNewBuildingProp.NEW_DE,
  OldNewBuildingProp.NEW_EN,
]

export const OldBuildingProp = [
  OldNewBuildingProp.OLD_DE,
  OldNewBuildingProp.OLD_EN,
  OldNewBuildingProp.OLD_IT,
]
